import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import {
  Anchor,
  Badge,
  LoadingOverlay,
  SimpleGrid,
  Button,
  Grid,
  Col,
  Spoiler,
  Breadcrumbs,
  Text,
  Divider,
  ActionIcon,
  Tooltip,
  Popper,
} from "@mantine/core";
import { User } from "../../../entity/User";
import { useNotifications } from "@mantine/notifications";
import storage from "../../../auth/storage";
import { useModals } from "@mantine/modals";
import creditHistory from "../../../api/creditHistory";
import formation from "../../../api/formation";
import users from "../../../api/users";
import { FaUser } from "react-icons/fa";
import {
  FiUserCheck,
  FiUserX,
  IoIosCopy,
  IoMdOpen,
  MdOutlineCancel,
    FaTrash
} from "react-icons/all";
import { useClipboard, useWindowScroll } from "@mantine/hooks";

import HasRoles from "../../../components/Security/hasRoles";
import {
  ROLE_BEMAN,
  ROLE_BEMAN_MORE,
  ROLE_DXMAN_MORE,
  ROLE_XMAN,
} from "../../../constants/roles";
import { BiChevronLeft } from "react-icons/bi";
import { FaCopy } from "react-icons/fa";
import { RiFolderSharedLine } from "react-icons/ri";
import Footer from "../../Footer";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import workshop from "../../../api/workshop";
import { CSVLink, CSVDownload } from "react-csv";
import dayjs from "dayjs";
import { LinkProps } from "react-csv/components/Link";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import { Modal } from "@mantine/core";
import {
  format_d_MM_YYYY,
  format_d_MMMM_YYYY,
  format_dddd_d_MM_YYYY,
  format_dddd_d_MMMM_YYYY
} from "../../../utility/dateUtility";
import ItemFormationWrapped from "./ItemFormationWrapped";

const ItemFormation = () => {
  const { id } = useParams();
  const [openedPaypal, setopenedPaypal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [totalBasePrice, setTotalBasePrice] = useState(Number);
  const [Completed, setCompleted] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const { request, loading } = useApi(formation.item);
  const [dataFormation, setDataFormation] = useState<any>();

  // @ts-ignore
  return (
    <>
      <LoadingOverlay visible={loading} />
      <LoadingOverlay visible={requestLoading} />
      <Modal
        opened={openedPaypal}
        onClose={() => setopenedPaypal(false)}
        title="Paiement par Paypal"
      >
        <ButtonWrapper
          Completed={() => setCompleted(true)}
          currency="EUR"
          showSpinner={false}
          amount={totalPrice}
          totalBasePrice={totalBasePrice}
          itemName={dataFormation?.title}
          //@ts-ignore
          FormationId={parseInt(id)}
          //@ts-ignore
          formation={parseInt(id)}

        />
      </Modal>
      <ItemFormationWrapped
        id={id}

        openedPaypal={openedPaypal}
        setopenedPaypal={setopenedPaypal}

        dataFormation={dataFormation}
        setDataFormation={setDataFormation}

        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        setTotalBasePrice={setTotalBasePrice}

        Completed={Completed}
        setCompleted={setCompleted}
        
        setRequestLoading={setRequestLoading}
        requestLoading={requestLoading}

        request={request}
        loading={loading}
      />
    </>
  );
};

export default ItemFormation;
