import {useEffect, useRef, useState} from "react";
import {PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import useApi from "../../hooks/useApi";
import transactions from '../../api/transactions';
import users from "../../api/users";
import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import storage from '../../auth/storage'
// This values are the props in the UI
import {useNotifications} from "@mantine/notifications";
import payment from "../../api/payment";
import {Button} from "@mantine/core";
import useAuth from "../../auth/useAuth";
import {useNavigate} from "react-router-dom";
import { REACT_APP_STRIPE_PUBLIC_KEY } from "../../env";

const currency = "EUR";
const style = {color: "blue"};

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({
                           currency,
                           showSpinner,
                           amount,
                           totalBasePrice = "",
                           itemName,
                           WorkshopId,
                           FormationId,
                           Completed,
                           workshop,
                           formation,
                           isAdhesion = false,
                           noRefund = false,
                           Close
                       }: any) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{options, isPending}, dispatch] = usePayPalScriptReducer();
    const {request: requestTransaction} = useApi(transactions.CreateTransaction)
    const {request: applyCreditOnTransaction} = useApi(transactions.applyCreditOnTransaction)
    const {request: requestTransactionAdhesion} = useApi(transactions.CreateTransactionAdhesion)
    const {data: dataUser, request: requestUser} = useApi(users.me);
    const {data: dataUser1, request: requestUser1} = useApi(users.getUserById);
    const [dataTransactionPaypal, setdataTransactionPaypal] = useState({});
    const { request: requestNeoUser } = useApi(users.editNeoMan);
    const { request: requestAdhesionUpdate } = useApi(users.adhesionUpdate);
    const { request: requestAdhesion } = useApi(users.adhesion);
    const [completed, setcompleted] = useState(false);
    const notifications = useNotifications();
    const [isError, setIsError] = useState(false);
    const {id}: any = storage.getUser();
    const refSubmit = useRef<HTMLButtonElement|undefined>();
    const { logout } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    // @ts-ignore
    const postPayment = (response) =>{
        // @ts-ignore
        const creditToPay = window.creditToPayFixed
        console.log({creditToPay})
        // @ts-ignore
        const transaction_id = response.data?.id;
        if(!creditToPay){
            // @ts-ignore
            return true;
        }
        applyCreditOnTransaction({
            transaction_id,
            credit_amount:creditToPay,
            noRefund,
        })
    }


    const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


    const handleSubmit = (stripe: any, elements: any) => async () => {
        // @ts-ignore
        window.creditToPayFixed = undefined;
        if (!stripe || !elements) {
            console.log("r",elements)
            return;
        }
        // @ts-ignore
        refSubmit.current.disabled = true;
        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
            // @ts-ignore
            //refSubmit.current.disabled = false;
        } else {
            // @ts-ignore
            window.creditToPayFixed = window.creditToPay
            if (isAdhesion) {
                payment({
                    amount: amount * 100,
                    workshop_id: 0,
                    user_id: id,
                    name: "adhésion",
                    payment_method: paymentMethod.id
                }).then((resa: any) => {
                    console.log("paiement resa", resa)
                    stripe?.confirmCardPayment(resa.data, {
                        payment_method: paymentMethod.id,
                    }).then((res: any) => {
                        if (res.error) {
                            // @ts-ignore
                            //refSubmit.current.disabled = false;
                            console.log("paiement erreur 1", res.error);
                            notifications.showNotification({
                                title: 'Une erreur est survenue.',
                                message: "Le paiement n'a pas pu aboutir ! 🤥",
                                color: "red"
                            })
                        } else {
                            // @ts-ignore
                            //refSubmit.current.disabled = false;
                            Completed();
                            notifications.showNotification({
                                title: 'Nice !',
                                message: "Le paiement a été Effectué avec succés ! 😀",
                                color: "green"
                            })
                            console.log(id)
                            requestUser1(id.toString()).then((res) => {
                                console.log("INFO",res)
                                const date1 = new Date();
                                requestTransactionAdhesion({
                                    user: id,
                                    email: res?.data?.email,
                                    lastName: res?.data?.lastName,
                                    firstName: res?.data?.firstName,
                                    society: res?.data?.society,
                                    city: res?.data?.city,
                                    address: res?.data?.address,
                                    postalCode: res?.data?.postalCode,
                                    createAt: new Date(),
                                    paypalIdTransaction: "STRIPE PAYMENT ADHESION",
                                    paypalAccountStatus: "COMPLETED",
                                    //@ts-ignore
                                    paymentPaypalDate: (date1.getFullYear() + "-" + date1.getMonth() + "-" + date1.getDate()),
                                    paymentMethod: "PAYMENT_BY_STRIPE",
                                    total: amount.toString(),
                                    baseTotal: (totalBasePrice).toString(),
                                    quantity: 1,
                                    itemName: amount > 30 ? "renouvellement cotisation Forman" : "Adhésion Forman",
                                    workshop: null,
                                    formation: null,
                                    idWorkshop: null,
                                    IdFormation: null
                                })
                                    .then((res1) => {
                                        // @ts-ignore
                                        postPayment(res1)
                                        // @ts-ignore
                                        //refSubmit.current.disabled = false;
                                        console.log("res", res)
                                        if (res.data.roles == "ROLE_GUEST" || res.data.roles == "ROLE_NOMAN") {
                                            requestNeoUser("NEOMAN", id);
                                            requestAdhesion(id);
                                            notifications.showNotification({
                                                title: "Nice !",
                                                message: "Vous êtes maintenant NEOMAN ! 😀",
                                                color: "green",
                                            });
                                        } else {
                                            requestAdhesionUpdate(id);
                                            notifications.showNotification({
                                                title: "Nice !",
                                                message: "Vous avez renouvellez votre adhésion ! 😀",
                                                color: "green",
                                            });
                                        }
                                        logout();
                                        navigate('/');
                                        setcompleted(true);
                                        console.log("transaction : ", res)
                                    })
                            })
                        }

                    }).catch((err: any) => {
                        // @ts-ignore
                        //refSubmit.current.disabled = false;
                        console.log("paiement erreur 2", err);
                        notifications.showNotification({
                            title: 'Une erreur est survenue.',
                            message: "Le paiement n'a pas pu aboutir ! 🤥",
                            color: "red"
                        })
                    })
                })
            } else {
                payment({
                    amount: amount * 100,
                    workshop_id: (WorkshopId || FormationId) ?? 0,
                    user_id: id,
                    name: itemName,
                    payment_method: paymentMethod.id
                }).then((resa: any) => {
                    stripe?.confirmCardPayment(resa.data, {
                        payment_method: paymentMethod.id,
                    }).then((res: any) => {
                        console.log("paiement ICICIICCIICICIC", res)
                        if (res.error) {
                            notifications.showNotification({
                                title: 'Une erreur est survenue.',
                                message: "Le paiement n'a pas pu aboutir ! 🤥",
                                color: "red"
                            })
                        } else {
                            Completed();
                            notifications.showNotification({
                                title: 'Nice !',
                                message: "Le paiement a été Effectué avec succés ! 😀",
                                color: "green"
                            })
                            requestUser().then((res) => {
                                requestTransaction({
                                    user: res?.data?.id,
                                    email: res?.data?.email,
                                    lastName: res?.data?.lastName,
                                    firstName: res?.data?.firstName,
                                    society: res?.data?.society,
                                    city: res?.data?.city,
                                    address: res?.data?.address,
                                    postalCode: res?.data?.postalCode,
                                    createAt: new Date(),
                                    paypalIdTransaction: "STRIPE PAYMENT",
                                    paypalAccountStatus: "COMPLETED",
                                    //@ts-ignore
                                    paymentPaypalDate: new Date(),
                                    paymentMethod: "PAYMENT_BY_STRIPE",
                                    total: amount.toString(),
                                    baseTotal: (totalBasePrice).toString(),
                                    quantity: 1,
                                    itemName: itemName,
                                    workshop: workshop,
                                    formation: formation,
                                    idWorkshop: WorkshopId,
                                    IdFormation: FormationId
                                })
                                    .then((res) => {
                                        // @ts-ignore
                                        //postPayment(res)
                                        // @ts-ignore
                                        window.lastInvoiceId = res?.data?.id
                                        // @ts-ignore
                                        setcompleted(true);
                                        console.log("transaction : ", res)
                                    })
                            })
                        }

                    }).catch((err: any) => {
                        // @ts-ignore
                        refSubmit.current.disabled = false;
                        console.log("paiement erreur 3", err);
                        notifications.showNotification({
                            title: 'Une erreur est survenue.',
                            message: "Le paiement n'a pas pu aboutir ! 🤥",
                            color: "red"
                        })
                    })
                })
            }
            // ... SEND to your API server to process payment intent
        }
    };


    const PaymentForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        return (
            <div className="mt-10">
                <CardElement options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}/>
                { // @ts-ignore

                <button ref={refSubmit} onClick={handleSubmit(stripe, elements)}
                        className="bg-purple-700 mt-5 w-full font-bold text-thirteen py-2 text-white rounded-md"
                >
                    Payer avec Stripe
                </button>
                }
            </div>
        );
    }

    const StripePaymentForm = () => (
        <Elements stripe={stripePromise}>
            <PaymentForm/>
        </Elements>
    );

    return (<>
            {(showSpinner && isPending) && <div className="spinner"/>}
            <PayPalButtons
                disabled={completed}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    console.log('createORderactions', actions);
                    console.log('createORder', data);
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    description: itemName + "identiant :" + WorkshopId ?? 0  +  FormationId ?? 0,
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            console.log('orderID', orderId)
                            return orderId;
                        });
                }}
                //createBillingAgreement={() =>  Promise.resolve('uduze')}
                onApprove={function (data, actions) {
                    console.groupEnd()
                    //@ts-ignore
                    return actions.order.capture().then((r) => {
                        if (r.status != "COMPLETED") {
                            notifications.showNotification({
                                title: 'Une erreur est survenue.',
                                message: "Le paiement n'a pas pu aboutir ! 🤥",
                                color: "red"
                            })
                        } else {
                            // @ts-ignore
                            window.creditToPayFixed = undefined
                            notifications.showNotification({
                                title: 'Nice !',
                                message: "Le paiement a été Effectué avec succés ! 😀",
                                color: "green"
                            })
                            // @ts-ignore
                            window.creditToPayFixed = window.creditToPay
                            if(isAdhesion){
                                requestUser().then((res) => {
                                    requestTransactionAdhesion({
                                        user: res?.data?.id,
                                        email: res?.data?.email,
                                        lastName: res?.data?.lastName,
                                        firstName: res?.data?.firstName,
                                        society: res?.data?.society,
                                        city: res?.data?.city,
                                        address: res?.data?.address,
                                        postalCode: res?.data?.postalCode,
                                        createAt: r?.create_time,
                                        paypalIdTransaction: r?.id,
                                        paypalAccountStatus: r?.status,
                                        //@ts-ignore
                                        paymentPaypalDate: r?.purchase_units[0]?.payments?.captures[0]?.create_time,
                                        paymentMethod: "PAYMENT_BY_PAYPAL",
                                        total: r?.purchase_units[0]?.amount?.value,
                                        baseTotal: (totalBasePrice).toString(),
                                        quantity: 1,
                                        itemName: amount > 30 ? "renouvellement cotisation Forman" : "Adhésion Forman",
                                        workshop: null,
                                        formation: null,
                                        idWorkshop: null,
                                        IdFormation: null
                                    }).then((res1) => {
                                        // @ts-ignore
                                        postPayment(res1)
                                        console.log("res", res)
                                        if (res.data.roles == "ROLE_GUEST" || res.data.roles == "ROLE_NOMAN") {
                                            requestNeoUser("NEOMAN", id);
                                            requestAdhesion(id);
                                            notifications.showNotification({
                                                title: "Nice !",
                                                message: "Vous êtes maintenant NEOMAN ! 😀",
                                                color: "green",
                                            });
                                        } else {
                                            requestAdhesionUpdate(id);
                                            notifications.showNotification({
                                                title: "Nice !",
                                                message: "Vous avez renouvellez votre adhésion ! 😀",
                                                color: "green",
                                             });
                                        }
                                        logout();
                                        navigate('/');
                                        setcompleted(true);
                                        console.log("transaction : ", res)
                                    })
                            })
                            }else{
                                requestUser().then((res) => {
                                    requestTransaction({
                                        user: res?.data?.id,
                                        email: res?.data?.email,
                                        lastName: res?.data?.lastName,
                                        firstName: res?.data?.firstName,
                                        society: res?.data?.society,
                                        city: res?.data?.city,
                                        address: res?.data?.address,
                                        postalCode: res?.data?.postalCode,
                                        createAt: r?.create_time,
                                        paypalIdTransaction: r?.id,
                                        paypalAccountStatus: r?.status,
                                        //@ts-ignore
                                        paymentPaypalDate: r?.purchase_units[0]?.payments?.captures[0]?.create_time,
                                        paymentMethod: "PAYMENT_BY_PAYPAL",
                                        total: r?.purchase_units[0]?.amount?.value,
                                        baseTotal: (totalBasePrice).toString(),
                                        quantity: 1,
                                        itemName: itemName,
                                        workshop: workshop,
                                        formation: formation,
                                        idWorkshop: WorkshopId,
                                        IdFormation: FormationId
                                    })
                                        .then((res) => {
                                            // @ts-ignore
                                            //postPayment(res)
                                            // @ts-ignore
                                            window.lastInvoiceId = res?.data?.id
                                            setcompleted(true);
                                            console.log("transaction : ", res)
                                        })
                                })
                            }
                            Completed()
                            setcompleted(true);
                        }

                        //create_time , id,payer{},purchase_units[],status
                        //payer{}: email_address ,name{given_name , surname}, payer_id
                        //purchase_units[]: amout{currency_code,value},payee{}(celui qui reçoit le paiement)
                        //payee : {email_adress,merchant_id},payments{captures[amout,create_time,id,status]}
                    });
                }}
            />
            <>
                <StripePaymentForm/>
            </>

        </>
    );
}

export default ButtonWrapper;