import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import event from "../../../api/event";
import {
  Anchor,
  Badge,
  LoadingOverlay,
  SimpleGrid,
  Button,
  Grid,
  Col,
  Spoiler,
  Breadcrumbs,
  Text,
  Divider,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { User } from "../../../entity/User";
import { Modal } from "@mantine/core";
import users from "../../../api/users";
import creditHistory from "../../../api/creditHistory";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import { useNotifications } from "@mantine/notifications";
import storage from "../../../auth/storage";
import { useModals } from "@mantine/modals";
import { CheckIcon } from "@heroicons/react/solid";
import { FaUser } from "react-icons/fa";
import {
  FiUserCheck,
  FiUserX,
  IoIosCopy,
  IoMdOpen,
  MdOutlineCancel,
} from "react-icons/all";
import { useClipboard, useWindowScroll } from "@mantine/hooks";
import { RiFolderSharedLine } from "react-icons/ri";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import CardCollection from "../../../components/event/CardCollection";
import HasRoles from "../../../components/Security/hasRoles";
import { ROLE_DXMAN_MORE, ROLE_XMAN } from "../../../constants/roles";
import { FaCopy } from "react-icons/fa";
import Footer from "../../Footer";
import { CSVLink, CSVDownload } from "react-csv";
import workshop from "../../../api/workshop";
import teamMeeting from "../../../api/teamMeeting";
import { format_d_MMMM_YYYY, format_dddd_d_MMMM_YYYY } from "../../../utility/dateUtility";

import ItemEventWrapped from "./ItemEventWrapped";

const ItemEvent = () => {
  const [openedPaypal, setopenedPaypal] = useState(false);
  const [dataEvent, setDataEvent] = useState<any>();
  const [totalPrice, setTotalPrice] = useState(Number);
  const [totalBasePrice, setTotalBasePrice] = useState(Number);
  const [Completed, setCompleted] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const { request, loading } = useApi(event.item);

  // @ts-ignore
  return (
    <>
      <LoadingOverlay visible={loading} />
      <LoadingOverlay visible={requestLoading} />
      <Modal
        opened={openedPaypal}
        onClose={() => setopenedPaypal(false)}
        title="Paiement par Paypal"
      >
        <ButtonWrapper
          Completed={() => setCompleted(true)}
          currency="EUR"
          showSpinner={false}
          amount={totalPrice}
          totalBasePrice={totalBasePrice}
          itemName={dataEvent?.title}

        />
      </Modal>
      <ItemEventWrapped
        setopenedPaypal={setopenedPaypal}
        setDataEvent={setDataEvent}
        setTotalPrice={setTotalPrice}
        setTotalBasePrice={setTotalBasePrice}
        openedPaypal={openedPaypal}
        dataEvent={dataEvent}
        totalPrice={totalPrice}

        Completed={Completed}
        setCompleted={setCompleted}
        
        requestLoading={requestLoading}
        setRequestLoading={setRequestLoading}
        request={request}
        loading={loading}
      />
    </>
  );
};

export default ItemEvent;
