import request from "./request";

const endpoint = '/transactions';
const endpoint_chorus_pro = '/chorus-pro';

const getAll = () => {
    return request.get(endpoint);
}

const getAllByUser = (userId:number) => {
    return request.get(endpoint + '?user.id=' + userId);
}

const getTransactionById = (id:number) => {
    return request.get(endpoint +"/"+id);
};

const getTransactionByAtelierUser = (data:any) => {
  var url = `/detail/transaction`;
  return request.post(url, {
        user_id: data.user_id,
        atelier_id: data.atelier_id
    });

  // return request.get(`${endpoint}?user.id=${userId}&workshop.id=${workshopId}`);
};

const getAvoirByUser = (data:any) => {
  var url = `/avoir/credit/user`;
  return request.post(url, {
        user_id: data.user_id,
    });

  // return request.get(`${endpoint}?user.id=${userId}&workshop.id=${workshopId}`);
};


const CreateTransaction = async (transaction:any) => {
  const res:any = await request.post(endpoint, {
  user: "/api/users/" + transaction.user,
  email: transaction.email,
  firstName: transaction.firstName,
  lastName: transaction.lastName,
  society: transaction.society,
  createAt: transaction.createAt,
  total: transaction.total,
  baseTotal: transaction.baseTotal,
  address: transaction.address,
  postalCode: transaction.postalCode,
  city: transaction.city,
  itemName: transaction.itemName,
  quantity: transaction.quantity,
  workshop: transaction.workshop === undefined ? null : "/api/workshops/" + transaction.workshop,
  formation: transaction.formation === undefined ? null : "/api/formations/" + transaction.formation,
  idWorkshop: intOrNull(transaction.idWorkshop),
  idFormation: transaction.idFormation,
  paymentMethod:transaction.paymentMethod,
  paymentPaypalDate:transaction.paymentPaypalDate,
  paypalAccountStatus:transaction.paypalAccountStatus,
  paypalIdTransaction: transaction.paypalIdTransaction,
  credit: transaction.credit === undefined ? null : "/api/credit_histories/" + transaction.credit
    })

  if(res && res.data && res.data.id && !transaction.disableChorusPro){
    await submitChorusProInvoice(res.data.id);
  }

  return res;
}

const CreateTransaction2 = (transaction:any) => {
  return request.post(endpoint, {
    user: transaction.user,
    email: transaction.email,
    firstName: transaction.firstName,
    lastName: transaction.lastName,
    society: transaction.society,
    createAt: transaction.createAt,
    total: transaction.total,
    address: transaction.address,
    postalCode: transaction.postalCode,
    city: transaction.city,
    itemName: transaction.itemName,
    quantity: transaction.quantity,
    workshop: transaction.workshop,
    formation: transaction.formation,
    idWorkshop: intOrNull(transaction.idWorkshop),
    idFormation: transaction.idFormation,
    paymentMethod:transaction.paymentMethod,
    paymentPaypalDate:transaction.paymentPaypalDate,
    paypalAccountStatus:transaction.paypalAccountStatus,
    paypalIdTransaction: transaction.paypalIdTransaction,

  })
}

const EditTransaction2 = (transaction:any, id:number) => {
  return request.put(endpoint + "/"+ id, {
    user: transaction.user,
    total: transaction.total,
    itemName: transaction.itemName,
    workshop: transaction.workshop,
    formation: transaction.formation,
    idWorkshop: intOrNull(transaction.idWorkshop),
    idFormation: transaction.idFormation,
    createAt: transaction.createAt,
  })
}

// @ts-ignore
const applyCreditOnTransaction = (params = {}) => {
  // @ts-ignore
  const {
    // @ts-ignore
    transaction_id,
    // @ts-ignore
    credit_amount,
    // @ts-ignore
    noRefund = false,
    // @ts-ignore
  } = params
  const url = `/invoice-apply-credit`;
  return request.post(url, {
    transaction_id: transaction_id,
    credit_amount: credit_amount,
    // @ts-ignore
    noRefund: noRefund ? "1" : "0",
  })
}

// @ts-ignore
const getDetails = (params = {}) => {
  // @ts-ignore
  const {
    // @ts-ignore
    transactionIds,
    // @ts-ignore
  } = params
  const url = `/invoice-credits-by-ids`;
  return request.post(url, {
    // @ts-ignore
    transactionIds: transactionIds,
    // @ts-ignore
  })
}

// @ts-ignore
const getDetailsAdmin = (params = {}) => {
  // @ts-ignore
  const {
    // @ts-ignore
    userId,
    // @ts-ignore
    transactionIds,
    // @ts-ignore
  } = params
  const url = `/invoice-admin-credits-by-ids`;
  return request.post(url, {
    // @ts-ignore
    transactionIds: transactionIds,
    userId: userId,
    // @ts-ignore
  })
}

const CreateTransactionAdhesion = async (transaction:any) => {
  const res:any = await request.post(endpoint, {
  user: "/api/users/" + transaction.user,
  email: transaction.email,
  firstName: transaction.firstName,
  lastName: transaction.lastName,
  society: transaction.society,
  createAt: transaction.createAt,
  total: transaction.total,
  address: transaction.address,
  postalCode: transaction.postalCode,
  city: transaction.city,
  itemName: transaction.itemName,
  quantity: transaction.quantity,
  workshop: null,
  formation: null,
  idWorkshop: null,
  idFormation: null,
  paymentMethod:transaction.paymentMethod,
  paymentPaypalDate:transaction.paymentPaypalDate,
  paypalAccountStatus:transaction.paypalAccountStatus,
  paypalIdTransaction: transaction.paypalIdTransaction,

    })

  if(res && res.data && res.data.id){
    await submitChorusProInvoice(res.data.id);
  }

  return res;
}

const submitChorusProInvoice = (transactionId:any) => {
  return request.post(endpoint_chorus_pro + "/submit-invoice", {
    transactionId: transactionId,
  })
}

const intOrNull = (value: any) => {
  if(!isNaN(parseInt(value))){
    return parseInt(value);
  }
  return null;
}

const exportedTransaction = {
    getAll,  
    getAllByUser,  
    getDetails,  
    getDetailsAdmin,  
    getTransactionById,
    CreateTransaction, 
    CreateTransactionAdhesion, 
    CreateTransaction2, 
    EditTransaction2,
    getTransactionByAtelierUser,
    applyCreditOnTransaction,
    getAvoirByUser
}

export default exportedTransaction; 