import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "@mantine/core";
import transactions from "../../api/transactions";
import useApi from "../../hooks/useApi";
import Footer from "../../templates/Footer";
import users from "../../api/users";
import isAdhesionPayment from "../../utility/isAdhesionPayment";
import settings from "../../api/settings";
import { BsFillFileEarmarkCheckFill } from "react-icons/bs";
import getSiret from "../../utility/getSiret";
import {
    getPriceHTBase,
    getPriceTVABase,
    getNumberTVA
} from "../../utility/tva";

const ItemFacturePreview = () => {
  const { user_id, facture_id } = useParams();
  // @ts-ignore
  const { request: transactionGetDetails } = useApi(transactions.getDetailsAdmin);

  // @ts-ignore
  const [transactionDetails, settransactionDetails] = useState({});

  const { 
    data,
    request,
  } = useApi(users.getUserById);

  const { 
    data: dataTVA,
    request: requestTVA,
  } = useApi(settings.getTva);
  
  const { 
    data: dataTransaction,
    request: requestTransaction,
  } = useApi(transactions.getTransactionById);

  const idTVA = 1;

  useEffect(() => {
    request(user_id).then((r) => {
      requestTVA(idTVA);
      requestTransaction(facture_id);
    });
  }, []);

  const getPriceHT = (price: string, tva: number) => {
    if (isAdhesionPayment(dataTransaction?.itemName)) {
      return parseInt(price);
    }
    return getPriceHTBase(price, tva);
  };
  const getPriceTVA = (price: string, tva: number) => {
    if (isAdhesionPayment(dataTransaction?.itemName)) {
      return 0;
    }
    return getPriceTVABase(price, tva);
  };

  const foot = (
    <>
      <tr>
        <th></th>
        <th></th>
        <th style={{ color: "white" }}>TOTAL (TTC) </th>
        <th style={{ color: "white" }}>{dataTransaction?.total}</th>
      </tr>
    </>
  );
  const row = (
    <>
      <tr>
        <td>{dataTransaction.quantity ?? 0}</td>
        <td className="text-center leading-6"> {dataTransaction?.itemName ?? ""}
          {dataTransaction?.workshop && " du " + new Date(dataTransaction?.workshop?.startDate).toLocaleDateString('fr-FR', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }
          {dataTransaction?.formation && " du " + new Date(dataTransaction?.formation?.startDate).toLocaleDateString('fr-FR', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }
          <div>{dataTransaction?.paymentMethod == 'PAYMENT_BY_CREDIT' && '(Paiement par crédit)'}</div>
        </td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA)?? 0}</td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
      <tr>
        <td>Date de paiement</td>
        <td className="text-center">{new Date(dataTransaction.createAt).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}</td>
        <td>TVA ({isAdhesionPayment(dataTransaction.itemName) ? 0 : dataTVA.valueTVA ?? 0} %)</td>
        <td>{getPriceTVA(dataTransaction.baseTotal ? dataTransaction.baseTotal : dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>TOTAL (euros HT) </td>
        <td>{getPriceHT(dataTransaction.total, dataTVA.valueTVA) ?? 0}</td>
      </tr>
    </>
  );

  // @ts-ignore
  useEffect(() => {
    // @ts-ignore
    let ids = [dataTransaction.id]
    // @ts-ignore
    if(ids.length){
      // @ts-ignore
      transactionGetDetails({transactionIds:ids, userId:user_id}).then((r) => {
        console.log({r})
        // @ts-ignore
        settransactionDetails(r?.data ? r.data : {})
      });
    }
  }, [dataTransaction]);

  return (
    <>
      <div className="pb-12 pt-6 md:pt12 px-10 text-gray-600">
        <div className="flex flex-row  gap-2">
          <h1 className="uppercase text-3xl"> FACTURE {dataTransaction.id}</h1>
        </div>
        <div className=" text-xl">
          {new Date(dataTransaction.createAt).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </div>
        <div className="flex flex-row justify-between mt-3 ">
          <div>
            <div>Association Forman</div>
            <div>2 bis rue Léon Blum</div>
            <div>78350 JOUY-EN-JOSAS</div>
          </div>
          <div className="text-right">
            <div className="mb-3">
              <div className="text-right">Identifiant client:</div>
              <div>
                {data?.id}
              </div>
            </div>
            <div>
              <div>Adresse Facturation client:</div>
              <div>
                {data?.userInformations?.societyName ? data?.userInformations?.societyName : data?.lastName + " " + data?.firstName}
              </div>
              <div>{data?.address}</div>
              <div>
                {data?.city} {data?.postalCode}
              </div>
            </div>
          </div>
        </div>
        <Table
          horizontalSpacing="md"
          verticalSpacing="md"
          className="text-3xl bg-white rounded shadow-xl mt-4 "
          style={{ backgroundColor: "#FFFEF7" }}
        >
          <thead>
            <tr>
              <th>Quantité</th>
              <th>Description</th>
              <th>Prix Unitaire (euros HT)</th>
              <th>Montant (euros HT)</th>
            </tr>
          </thead>
          <tbody>{row}</tbody>
          <tfoot style={{ backgroundColor: "#006098" }}>{foot}</tfoot>
        </Table>
        <div className="flex justify-between mt-4">
          <div className="text-xs flex flex-col">
            {isAdhesionPayment(dataTransaction?.itemName) ? <div>TVA non applicable,article 261 du CGI</div> : <></>}
            <div>
              indemnité forfaitaire pour frais de recouvrement de 40 euros
            </div>
            {(() =>{
              // @ts-ignore
              return Object.keys(transactionDetails).map((id, index) =>{
                // @ts-ignore
                if(id == dataTransaction.id){
                  // @ts-ignore
                  let credits = transactionDetails[id]
                  // @ts-ignore
                  let r = []
                  // @ts-ignore
                  if(credits.length){
                    // @ts-ignore
                    r.push(
                      <div style={{fontSize: 19, lineHeight: '35px'}} key={`credit-${id}-00-${index}`}>
                        Avoirs : 
                      </div>
                    )
                  }
                  // @ts-ignore
                  r.push(credits.map((credit, index) =>{
                    // @ts-ignore
                    var { amount, credit_id } = credit;
                    // @ts-ignore
                    return (
                      <div style={{fontSize: 17, lineHeight: '25px'}} key={`credit-${id}-${credit_id}-${index}`}>
                        - N° {credit_id} :  {amount} euro{amount > 1 ? 's' : ''}
                      </div>
                    )
                  }))
                  return r
                }
                return null;
              })
            })()}
          </div>
          <div className="text-xs font-bold bg-white text-green-500 p-3  rounded flex flex-row">
            {" "}
            <BsFillFileEarmarkCheckFill className="text-2xl" /> Facture Acquité
            le{" "}
            {new Date(dataTransaction.createAt).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </div>
        </div>
        <div className="mt-5 pt-5">
          <div className="text-xs text-center pt-5">
            Facture n° {dataTransaction?.id} - Association Forman - Siret : {getSiret()} - TVA n° : {getNumberTVA()}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ItemFacturePreview;
